<h2 mat-dialog-title class="font-weight-bold">{{ dialogData.title }}</h2>

<div mat-dialog-content>
    <div *ngIf="dialogData.description" [innerHTML]="dialogData.description" class="mb-5"></div>
    <form *ngIf="!dialogData.isCommissioning" [formGroup]="form" class="d-flex flex-column mt-3">
        <mat-form-field class="flex-grow-1">
            <input
                type="email"
                formControlName="sender"
                matInput
                [placeholder]="'DIALOGS.FEEDBACK.SENDER.LABEL' | translate"
            />
            <mat-icon class="mr-2" matPrefix>email</mat-icon>
            <mat-error>
                {{ 'COMMON.VALIDATION.EMAIL' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="flex-grow-1">
            <input
                [readonly]="dialogData.subject?.readonly"
                type="text"
                formControlName="subject"
                matInput
                [placeholder]="'DIALOGS.FEEDBACK.SUBJECT' | translate"
            />
            <mat-error>
                {{ 'DIALOGS.FEEDBACK.VALIDATION.SUBJECT.REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="flex-grow-1">
            <textarea
                rows="8"
                type="email"
                formControlName="message"
                matInput
                [placeholder]="'DIALOGS.FEEDBACK.MESSAGE.LABEL' | translate"
            ></textarea>
            <mat-error>
                {{ 'DIALOGS.FEEDBACK.VALIDATION.MESSAGE.REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button [mat-dialog-close]="false" (click)="archive()">{{ 'DIALOGS.COMMON.CANCEL' | translate }}</button>
    <button
        *ngIf="!dialogData.isCommissioning"
        mat-raised-button
        color="primary"
        [disabled]="!form.valid"
        (click)="closeDialog()"
    >
        <mat-icon>send</mat-icon>
        {{ 'DIALOGS.COMMON.SEND' | translate }}
    </button>
    <a
        *ngIf="dialogData.isCommissioning"
        (click)="archive()"
        mat-raised-button
        [mat-dialog-close]="false"
        color="primary"
        [href]="'DROPOUT.COMMISSIONING.BUTTON.HREF' | translate"
        target="_blank"
    >
        {{ 'DROPOUT.COMMISSIONING.BUTTON.LABEL' | translate }}
    </a>
</div>
