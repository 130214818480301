import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ArchiveService } from '../../archive/archive.service';
import { Router } from '@angular/router';
import { Configuration } from '../../quote/quote.component';

export interface FeedbackDialogData {
    title: string;
    description?: string;
    sender?: string;
    subject?: {
        value: string;
        readonly: boolean;
    };
    message?: string;
    archive?: boolean;
    configuration?: Configuration;
    isCommissioning?: boolean;
}

export interface FeedbackDialogResult {
    sender: string;
    subject: string;
    message: string;
}

@Component({
    selector: 'app-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
    public form: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<FeedbackDialogComponent, FeedbackDialogResult>,
        @Inject(MAT_DIALOG_DATA) public dialogData: FeedbackDialogData,
        private archiveService: ArchiveService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.form = new FormGroup({
            sender: new FormControl<string>(this.dialogData.sender, [Validators.required, Validators.email]),
            subject: new FormControl<string>(this.dialogData.subject?.value, Validators.required),
            message: new FormControl<string>(this.dialogData.message, Validators.required),
        });
    }

    archive() {
        if (this.dialogData.archive) {
            this.archiveService
                .archiveService(this.dialogData.configuration.documentId, this.dialogData.configuration.revision)
                .subscribe(() => {
                    this.router.navigate(['/']);
                });
        } else {
            return false;
        }
    }

    closeDialog() {
        const message = this.form.controls.message.value.replace(/\r\n|\r|\n/g, '<br />'); // convert newline to <br> tags
        this.dialogRef.close({
            sender: this.form.controls.sender.value,
            subject: this.form.controls.subject.value,
            message: message,
        });
        this.archive();
    }
}
